<template>
  <div
    class="date-float"
    :style="{ height: isHeader ? '100%' : '100px' }"
    @mouseenter="onMouseover"
    @mouseleave="onMouseout"
  >
    <div
      v-if="!isDate && !isHeader"
      class="status"
      :class="'status__' + handlerText(data, item).class"
      @click="onShow"
    >
      {{ handlerText(data, item).text }}
    </div>
    <div v-if="isDate" class="date" @click="onShow">
      <div class="date__week">{{ getWeek }}</div>
      <div class="date__text">{{ dateText }}</div>
    </div>
    <div v-if="isHeader" class="date" @click="onShow">
      {{ data.reserveTime }}
    </div>
    <transition name="el-fade-in-linear">
      <div v-if="isShow" class="popup__erp" @click="onShow">请查看ERP数据</div>
    </transition>
    <transition name="el-fade-in-linear">
      <div v-if="isClickShow" class="popup" @click="onHide">
        <el-button
          :type="data.isDisable == 1 ? 'primary' : 'danger'"
          size="mini"
          @click="onChange"
          >{{ data.isDisable == 1 ? '启用' : '禁用' }}</el-button
        >
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    item: {
      type: String,
      default: '',
    },
    isDate: {
      type: Boolean,
      default: false,
    },
    isHeader: {
      type: Boolean,
      default: false,
    },
    isNeedOpen: {
      type: Boolean,
      default: true,
    },
    reserveDate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isShow: false,
      isClickShow: false,
    }
  },
  computed: {
    getWeek: function () {
      if (!this.data.reserveDate) return
      const day = new Date(this.data.reserveDate).getDay()
      const week = ['周天', '周一', '周二', '周三', '周四', '周五', '周六']
      return week[day]
    },
    dateText: function () {
      if (!this.data.reserveDate) return
      const dateArr = this.data.reserveDate.split('-')
      return dateArr[1] + '/' + dateArr[2]
    },
  },
  mounted() {},
  methods: {
    handlerText(item, prop) {
      if (item && item.reserveTime === prop) {
        if (Number(item.isRest) === 1) {
          return {
            text: '休息',
            class: 'not',
          }
        }
        if (item.isDisable)
          return {
            text: '已禁用',
            class: 'not',
          }
        if (Number(item.reserveStatus) === 0) {
          return {
            text: '',
            class: '0',
          }
        } else {
          return {
            text: '已预约',
            class: '1',
          }
        }
      }
    },
    /**
     * 鼠标移入
     */
    onMouseover() {
      if (
        Number(this.data.isRest) === 1 ||
        this.isHeader ||
        this.isDate ||
        this.data.isDisable ||
        Number(this.data.reserveStatus) === 1
      )
        return
      this.isShow = true
    },
    /**
     * 鼠标移出
     */
    onMouseout() {
      this.isShow = false
    },
    onShow() {
      if (
        !this.isNeedOpen ||
        Number(this.data.isRest) === 1 ||
        Number(this.data.reserveStatus) === 1
      )
        return
      this.isClickShow = true
    },
    onHide() {
      this.isClickShow = false
    },
    onChange() {
      this.isClickShow = false
      this.$emit('change', {
        data: this.data,
        item: this.item,
        isHeader: this.isHeader,
        isDate: this.isDate,
        reserveDate: this.reserveDate,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.date-float {
  width: 100%;
  height: 100px;
  padding: 4px;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
}
.date {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  white-space: nowrap;
  &__week {
    color: #999999;
    font-size: 13px;
    margin-bottom: 4px;
    align-self: flex-start;
    padding-left: 25px;
  }
  &__text {
    color: #111111;
    font-size: 18px;
  }
}
.status {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  &__0 {
    background-color: transparent;
    // color: #67c23a;
    font-size: 10px;
    color: rgba(17, 17, 17, 0.35);
  }
  &__1 {
    background-color: #d9f4f2;
    color: #00a199;
  }
  &__not {
    background-color: #e8e8e8;
    color: #9a9d9e;
  }
}
.popup {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: #f6f9f9;
  border: 1px solid #2861e2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup__erp {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: #f6f9f9;
  border: 1px solid #2861e2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(17, 17, 17, 0.35);
}
</style>
