<template>
  <div class="shedSpaceDetail">
    <el-link icon="el-icon-arrow-left" :underline="false" @click="onGoBack">棚位预约详情</el-link>
    <div class="search">
      <el-date-picker
        v-model="search.dateTime"
        type="daterange"
        :picker-options="pickerOptions"
        range-separator="至"
        value-format="yyyy-MM-dd"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        align="right"
        @change="onSearch"
      ></el-date-picker>
      <div class="tips">距离档期开始时间5分钟之内的不支持操作</div>
    </div>
    <el-table
      v-if="loading"
      :data="dataList"
      border
      :header-cell-style="{ height: '40px' }"
      height="700px"
    >
      <!-- 第一列，展示日期 -->
      <el-table-column label="日期" prop="date" align="center" fixed="left" width="100px">
        <template slot-scope="scope">
          <dateFloat
            :reserve-date="scope.row.reserveDate"
            :data="scope.row"
            is-date
            :is-need-open="false"
            @change="onChange(scope.row)"
          ></dateFloat>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in tableHeader"
        :key="index"
        :label="item.reserveTime"
        :prop="item.reserveTime"
        align="center"
      >
        <template slot="header">
          <!-- 表头 -->
          <dateFloat
            :data="item"
            is-header
            :is-need-open="false"
            @change="onChange($event, index)"
          ></dateFloat>
        </template>
        <template slot-scope="scope">
          <!-- 表数据 -->
          <dateFloat
            :reserve-date="scope.row.reserveDate"
            :data="scope.row.dateTimes[index]"
            :is-need-open="false"
            :item="item.reserveTime"
            @change="onChange($event, index)"
          ></dateFloat>
        </template>
      </el-table-column>
    </el-table>
    <div v-loading="!loading" style="height: 100px"></div>
  </div>
</template>
<script>
import dateFloat from '@/components/date-float/index.vue'
import { getReserveDetail, updateStatus } from '@/api/shed-space.js'
import dayjs from 'dayjs'
export default {
  components: { dateFloat },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近半年',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 180)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      tableHeader: [],
      dataList: [],
      search: {
        dateTime: '',
      },
      shedId: '',
      loading: false,
    }
  },
  created() {
    this.shedId = this.$route.query.shedId
    const end = new Date()
    const start = new Date()
    end.setTime(end.getTime() + 3600 * 1000 * 24 * 7)
    this.search.dateTime = [dayjs(start).format('YYYY-MM-DD'), dayjs(end).format('YYYY-MM-DD')]
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = false
      getReserveDetail({
        data: {
          shedId: this.shedId,
          numberOfDays: 0,
          startDate: this.search.dateTime[0],
          endDate: this.search.dateTime[1],
        },
      }).then((res) => {
        console.log(res)
        this.tableHeader = res.data.times
        this.dataList = res.data.dates
        this.dataList = res.data.dates.map((e) => {
          const isRest = e.dateTimes.some((el) => el.isRest === 1)
          e.isRest = isRest ? 1 : 0
          return e
        })
        this.loading = true
      })
    },
    onSearch(e) {
      console.log(e)
      this.search.dateTime = e
      this.getData()
    },
    handlerText(item, prop) {
      console.log(item, prop)
      if (item && item.time === prop) {
        if (item.isDisable)
          return {
            text: '已禁用',
            class: 'not',
          }
        if (item.status === 0) {
          return {
            text: '未预约',
            class: '0',
          }
        } else {
          return {
            text: '已预约',
            class: '1',
          }
        }
      }
    },
    onChange(row) {
      console.log(row)
      const form = {}
      if (row.isHeader) {
        form.reserveTime = row.data.reserveTime
        form.isDisable = row.data.isDisable === 0 ? 1 : 0
      } else {
        form.reserveDate = row.reserveDate
        form.isDisable = row.isDisable === 0 ? 1 : 0
        if (row.data) {
          form.reserveTime = row.data.reserveTime
          form.isDisable = row.data.isDisable === 0 ? 1 : 0
        }
      }
      form.storeShedId = this.shedId
      updateStatus({
        data: form,
      }).then((res) => {
        console.log(res)
        this.getData()
      })
    },
    arraySpanMethod({ row, columnIndex }) {
      if (columnIndex === 1) {
        if (row.dateTimes[0].isRest === 1) {
          return {
            rowspan: 1,
            colspan: row.dateTimes.length,
          }
        }
      }
    },
    onGoBack() {
      this.$router.push({
        name: 'supplyShedSpaceList',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.shedSpaceDetail {
  background-color: #fff;
  padding: 12px;
}
.search {
  margin-top: 30px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}
.tips {
  margin-left: 20px;
  font-size: 14px;
  color: #999;
}
.status {
  width: 100%;
  height: 100%;
  padding: 8px 0;
  box-sizing: border-box;
  text-align: center;
  &__0 {
    background-color: #fff;
    color: #67c23a;
  }
  &__1 {
    background-color: #f56c6c;
    color: #fff;
  }
  &__not {
    background-color: #999;
    color: #fff;
  }
}
::v-deep .el-table__cell {
  padding: 0 !important;
  height: 100%;
}
::v-deep .el-table__cell > .cell {
  padding: 0 !important;
  white-space: nowrap;
  height: 100%;
}
::v-deep thead .el-table__cell .cell {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
::v-deep .el-table th.el-table__cell {
  background-color: #f5f5f5;
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background-color: transparent;
}
.dropdown {
  width: 100%;
}
</style>
